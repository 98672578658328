import React, { useEffect } from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { StaticImage } from "gatsby-plugin-image"

import "../../styles/aston-martin.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"


const AstonMartinPage = (props: PageProps) => {
  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

  ScrollTrigger.defaults({ ignoreMobileResize: true });
  
  useEffect(() => {
    const heros = gsap.utils.toArray('.aston-martin--hero');
    const quotes = gsap.utils.toArray('.aston-martin--quote');
    const split = new SplitText(".split-text p", { type: "lines" });
    const tracks = gsap.utils.toArray(".sticky-element");

    let someDelay = gsap.delayedCall(0.2, resetGsapFunction).pause();
    function windowResize() {
      someDelay.restart(true)
    }
    window.addEventListener("resize", windowResize);

    function resetGsapFunction() {
      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
      split.split();
      gsapFunction();
    }
    

    gsapFunction();

    function gsapFunction() {
      heros.forEach((image) => {
        const asset = image.querySelector(".aston-martin--asset");
      
          gsap.to(asset, {
            y: () => asset.offsetHeight - image.offsetHeight,
            ease: "none",
            scrollTrigger: {
              trigger: image,
              scrub: true,
              pin: false,
              invalidateOnRefresh: true
            },
          }); 
      });

      quotes.forEach((image, i) => {
        const content = image.querySelector('.aston-martin--image');
        const asset = image.querySelector(".aston-martin--asset");
        
        gsap.fromTo(
          content,
          { scale: 1.2 },
          {
            scale: 1,
            ease: "none",
            force3D: true,
            scrollTrigger: {
              // pin: parent,
              trigger: asset,
              start: 'top center',
              end: 'bottom center',
              // pinType: isTouch ? "fixed" : "transform",
              pinType: "transform",
              scrub: 0.5,
            }
          }
        );
      });

      split.lines.forEach((target) => {
        gsap.to(target, {
          backgroundPositionX: 0,
          ease: "none",
          scrollTrigger: {
            trigger: target,
            scrub: 0.5,
            start: "top center",
            end: "bottom center"
          }
        });
      });

      tracks.forEach((track, i) => {
        let trackWrapper = track.querySelectorAll(".track");
        let allImgs = track.querySelectorAll(".image");

        let trackWrapperWidth = () => {
          let width = 0;
          trackWrapper.forEach(el => width += el.offsetWidth);
          return width;
        }
        
        let scrollTween = gsap.to(trackWrapper, {
          x: () => -trackWrapperWidth() + window.innerWidth,
          scrollTrigger: {
            trigger: track,
            pin: true,
            scrub: 1,
            start: "center center",
            end: () => "+=" + (track.scrollWidth - window.innerWidth),
            onRefresh: self => self.getTween().resetTo("totalProgress", 0), // fixes a very minor issue where the progress was starting at 0.001.
            invalidateOnRefresh: true,
            id: "id-one"
          },
        });

        allImgs.forEach((img, i) => {
          // the intended parallax animation
          gsap.fromTo(img, {
            x: "-60px"
          }, {
            x: "60px",
            scrollTrigger: {
              trigger: img.parentNode, //.panel-wide
              containerAnimation: scrollTween,
              start: "left right",
              end: "right left",
              scrub: true,
              invalidateOnRefresh: true,
              onRefresh: self => {
                if (self.start < 0) {
                  self.animation.progress(gsap.utils.mapRange(self.start, self.end, 0, 1, 0));
                }
              },
            },
          });
        });


const rows = 7;
const columns = 5;
const missingImages = 0; // The number of missing images in the last row
const frame_count = rows * columns - missingImages  - 1;
const imageWidth = 5120;
const imageHeight = 4032;
const horizDiff = imageWidth / columns;
const vertDiff = imageHeight / rows;

const viewer = document.querySelector(".aston-martin--360 canvas");
const ctx = viewer.getContext("2d");
viewer.width = horizDiff;
viewer.height = vertDiff;

const image = new Image();
image.src = "/aston-martin__sprite_optimised.jpg";
image.onload = () => onUpdate();

const obj = {num: 0};
gsap.to(obj, {
  num: frame_count,
  ease: "steps(" + frame_count + ")",
  scrollTrigger: {
    trigger: ".aston-martin--360",
    start: "top top",
    end: "+=" + imageHeight,
    pin: true,
    anticipatePin: 1,
    scrub: 1
  },
  onUpdate

});

function onUpdate() {
  ctx.clearRect(0, 0, horizDiff, vertDiff);
  const x = Math.round((obj.num % columns) * horizDiff);
  const y = Math.round(Math.floor(obj.num / columns) * vertDiff);
  ctx.drawImage(image, x, y, horizDiff, vertDiff, 0, 0, horizDiff, vertDiff);
}
        
        
      });
    }

    return () => {
      window.removeEventListener("resize", windowResize);
    };

  }, [])

  return (
    <Layout page="work" route="/portfolio/aston-martin">
      <Seo 
        image={'/aston-martin/cover.jpg'}
        title="Aston Martin by Alistair Bancroft"
        description={"In creating an immersive website that adds a new dimension and captivating story, our challenge was to craft a digital platform that truly embodies the essence of the Aston Martin F1 team."}
      />
      <div className="aston-martin-namespace">
        <ProjectHero
          background="astonMartin"
          agency={'ekino / Havas Helia'}
          body={["In creating an immersive website that adds a new dimension and captivating story, our challenge was to craft a digital platform that truly embodies the essence of the Aston Martin F1 team. As a space for fans to actively engage with all aspects of the team's endeavors, our goal was to not only provide information but to reflect the world beyond the track. Our vision was to push the boundaries, progressively driving both the team and the sport forwards, while inviting everyone along for an exhilarating ride.", "Through ingenious UI and animation blocks, we aimed to give voice to all kinds of F1 enthusiasts, encouraging user-generated stories and behind-the-scenes content.  The challenge was to seamlessly integrate these animation blocks to add a layer of interactivity and storytelling, captivating fans and elevating their overall experience on the Aston Martin F1 website."]}
          engagement={'2021 to present'}
          technologies={'Contentful, PugJS, Locomotive Scroll, GSAP, TailwindCSS'}
          position={'Front End Lead / ekino'}
          project={{
            label: 'ASTON MARTIN F1',
          }}
          title={'Project: Aston Martin Formula One'}
          subtitle={'Elevating front-end engagement for Aston Martin F1'}
          url={{
            href: 'https://www.astonmartinf1.com/',
            label: 'www.astonmartinf1.com',
          }}
        />

        <section data-background="ltAstonMartin" className="font-sans h-screen overflow-hidden relative w-full aston-martin--hero">
          <div className="absolute bottom-0 h-[150%] left-0 w-full aston-martin--asset">
            <StaticImage
              placeholder="blurred"
              alt="Aston Martin F1"
              className="h-full object-cover w-full"
              src="../../images/portfolio/aston-martin/aston-martin--banner-1.jpg"
            />
          </div>
          <header className="absolute bottom-0 p-5 pb-12 md:p-10 md:pb-20 text-white z-10">
            <p className="font-sans mb-5 text-sm md:text-base uppercase">The debrief by Aramco</p>
            <h2 className="font-sans text-3xl md:text-5xl">Fernando charges to P2 in Canada</h2>
          </header>
        </section>
        
        <section data-background="ltAstonMartin" className="font-sans h-screen overflow-hidden relative w-full aston-martin--hero">
          <div className="absolute bottom-0 h-[150%] left-0 w-full aston-martin--asset">
            <iframe className="h-full object-cover w-full" width="560" height="315" src="https://www.youtube.com/embed/_irNehv7zYg?rel=0&amp;autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <header className="absolute bottom-0 p-5 pb-12 md:p-10 md:pb-20 text-white z-10">
            <p className="font-sans mb-5 text-sm md:text-base uppercase">#IAMSTORIES</p>
            <h2 className="font-sans text-3xl md:text-5xl">F1 Singapore Grand Prix 2022</h2>
          </header>
        </section>

        <section data-background="ltAstonMartin" className="font-sans h-screen overflow-hidden relative w-full aston-martin--hero">
          <div className="absolute bottom-0 h-[150%] left-0 w-full aston-martin--asset">
            <StaticImage
              placeholder="blurred"
              alt="Aston Martin F1"
              className="h-full object-cover w-full"
              src="../../images/portfolio/aston-martin/aston-martin--banner-2.jpg"
            />
          </div>
          <header className="absolute bottom-0 p-5 pb-12 md:p-10 md:pb-20 text-white z-10">
            <p className="font-sans mb-5 text-sm md:text-base uppercase">Canadian Grand Prix</p>
            <h2 className="font-sans text-3xl md:text-5xl">Sixth podium of the season</h2>
          </header>
        </section>
        <ProjectTitle
          background="ltAstonMartin"
          text={{
            title: 'astonMartin',
          }}
          content={[{
            title: 'Dynamic Parallax Effects',
            body: ["Enhancing front-end visual appeal with dynamic parallax effects, the strategic implementation of which enriches the front-end visual experience. This addition adds depth and interactivity that captivates users and elevates the overall aesthetic appeal of the website. Parallax scrolling is particularly effective in scenarios involving powerful visual galleries or linear storytelling, allowing Aston Martin to bring enhanced functionality and energy to it's homepage."]
          }]}
        />

        <section data-background="ltAstonMartin" className="aspect-[1024/576] bg-astonMartin-secondary flex h-screen items-center justify-center overflow-hidden w-full aston-martin--360">
          <canvas className="object-cover w-full"></canvas>
        </section>
        <ProjectTitle
          background="ltAstonMartin"
          text={{
            title: 'astonMartin',
          }}
          content={[{
            title: '360 Immersive Visuals',
            body: ["By harnessing the immersive potential of 360 images, Aston Martin has successfully elevated user engagement to new heights. These stunning visuals unlock a realm of interactivity, empowering visitors to delve into captivating experiences and explore the latest car launches with unparalleled depth and excitement."]
          }]}
        />

        <section data-background="ltAstonMartin" className="bg-astonMartin-primary md:flex font-sans min-h-screen overflow-hidden relative w-full aston-martin--quote">
          <div className="h-full overflow-hidden w-full aston-martin--asset">
            <StaticImage
              placeholder="blurred"
              alt="Aston Martin F1"
              className="h-half-screen md:h-screen object-cover w-full aston-martin--image"
              src="../../images/portfolio/aston-martin/aston-martin--banner-3.jpg"
            />
          </div>
          <div className="split-text w-full">
            <blockquote className="flex min-h-half-screen h-full items-center py-10 px-5 md:pr-[7vw] md:pl-[4vw]">
              <div className="text-3xl md:text-4xl leading-relaxed md:leading-relaxed">
                <p>If a group of guys can dress in onesies and get away with it, then why not?!</p>
              </div>
            </blockquote>
          </div>
        </section>

        <section data-background="ltAstonMartin" className="bg-astonMartin-primary md:flex font-sans min-h-screen overflow-hidden relative w-full aston-martin--quote">
          <div className="split-text w-full">
            <blockquote className="flex min-h-half-screen h-full items-center py-10 px-5 md:px-[14vw]">
              <div>
                <p className="text-3xl md:text-5xl leading-relaxed md:leading-relaxed mb-10">We recognise that we don't just have an opportunity to use our global platform for good, we have a responsibility to do so – to inspire people and have a positive impact on society.</p>
                <div className="text-sm leading-relaxed md:leading-relaxed text-white">Martin Whitmarsh-Group CEO, Aston Martin Performance Technologies</div>
              </div>
            </blockquote>
          </div>
        </section>

        <ProjectTitle
          background="ltAstonMartin"
          text={{
            title: 'astonMartin',
          }}
          content={[{
            title: 'Elevating UX with Animated Copy',
            body: ["We have enhanced user experience with creative design elements like split text and animated copy. These interactive features bring our front-end to life, making content more engaging and memorable for our visitors.", 'With improved readability and captivating animations, our digital realm offers an unforgettable journey for every user.']
          }]}
        />

        <section data-background="ltAstonMartin" className="bg-astonMartin-secondary flex h-screen items-center justify-start overflow-hidden relative w-full carousel sticky-element">
          <header className="absolute inset-x-0 m-auto text-center top-[20%] z-10">
            <h3 data-speed="1.2" className="font-bold font-sans leading-none m-0 opacity-10 text-[10vw] md:text-[8vw] text-white uppercase">Highlights</h3>
          </header>
          <div className="relative z-10 track">
            <div className="flex gap-8 items-center justify-start track-flex">
              <div className="relative panel">
              <div className="h-full overflow-hidden">
                  <div className="h-full w-full image">
                    <StaticImage
                      placeholder="blurred"
                      alt="Aston Martin F1"
                      className="aspect-square h-full object-cover asset"
                      src="../../images/portfolio/aston-martin/aston-martin--banner-4.jpg"
                    />
                  </div>
                </div>
                <div className="absolute border-t-8 border-astonMartin-secondary bg-astonMartin-secondary bottom-0 pt-4 md:flex justify-between text-white top-full w-full">
                  <div className="pr-4 text-xs w-full md:w-[140px]">
                    <div className="mb-1 uppercase">On Track</div>
                    <div>23 July 2023</div>
                  </div>
                  <div className="hidden md:block -mt-1 text-base w-full">
                    The Debrief by Aramco: Hungarian GP
                  </div>
                </div>
              </div>
              <div className="relative panel">
              <div className="h-full overflow-hidden">
                  <div className="h-full w-full image">
                    <StaticImage
                      placeholder="blurred"
                      alt="Aston Martin F1"
                      className="aspect-square h-full object-cover asset"
                      src="../../images/portfolio/aston-martin/aston-martin--banner-5.jpg"
                    />
                  </div>
                </div>
                <div className="absolute border-t-8 border-astonMartin-secondary bg-astonMartin-secondary bottom-0 pt-4 md:flex justify-between text-white top-full w-full">
                  <div className="pr-4 text-xs w-full md:w-[140px]">
                    <div className="mb-1 uppercase">Feature</div>
                    <div>22 July 2023</div>
                  </div>
                  <div className="hidden md:block -mt-1 text-base w-full">
                  Mike on... the Hungarian Grand Prix
                  </div>
                </div>
              </div>
              <div className="relative panel">
              <div className="h-full overflow-hidden">
                  <div className="h-full w-full image">
                    <StaticImage
                      placeholder="blurred"
                      alt="Aston Martin F1"
                      className="aspect-square h-full object-cover asset"
                      src="../../images/portfolio/aston-martin/aston-martin--banner-6.jpg"
                    />
                  </div>
                </div>
                <div className="absolute border-t-8 border-astonMartin-secondary bg-astonMartin-secondary bottom-0 pt-4 md:flex justify-between text-white top-full w-full">
                  <div className="pr-4 text-xs w-full md:w-[140px]">
                    <div className="mb-1 uppercase">Feature</div>
                    <div>19 July 2023</div>
                  </div>
                  <div className="hidden md:block -mt-1 text-base w-full">
                  Aramco | The Incredible Science of F1
                  </div>
                </div>
              </div>
              <div className="relative panel">
              <div className="h-full overflow-hidden">
                  <div className="h-full w-full image">
                    <StaticImage
                      placeholder="blurred"
                      alt="Aston Martin F1"
                      className="aspect-square h-full object-cover asset"
                      src="../../images/portfolio/aston-martin/aston-martin--banner-7.jpg"
                    />
                  </div>
                </div>
                <div className="absolute border-t-8 border-astonMartin-secondary bg-astonMartin-secondary bottom-0 pt-4 md:flex justify-between text-white top-full w-full">
                  <div className="pr-4 text-xs w-full md:w-[140px]">
                    <div className="mb-1 uppercase">Feature</div>
                    <div>19 July 2023</div>
                  </div>
                  <div className="hidden md:block -mt-1 text-base w-full">
                  AvaTrade explains the F1 rulebook: The F1 calendar
                  </div>
                </div>
              </div>
              <div className="relative panel">
                <div className="h-full overflow-hidden">
                  <div className="h-full w-full image">
                    <StaticImage
                      placeholder="blurred"
                      alt="Aston Martin F1"
                      className="aspect-square h-full object-cover asset"
                      src="../../images/portfolio/aston-martin/aston-martin--banner-8.jpg"
                    />
                  </div>
                </div>
                <div className="absolute border-t-8 border-astonMartin-secondary bg-astonMartin-secondary bottom-0 pt-4 md:flex justify-between text-white top-full w-full">
                  <div className="pr-4 text-xs w-full md:w-[140px]">
                    <div className="mb-1 uppercase">Announcement</div>
                    <div>12 July 2023</div>
                  </div>
                  <div className="hidden md:block -mt-1 text-base w-full">
                    Valour. Celebrating 110 years of Aston Martin with brutish power and cutting-edge technology
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ProjectTitle
          background="ltAstonMartin"
          text={{
            title: 'astonMartin',
          }}
          content={[{
            title: 'Sticky Content Blocks',
            body: ["To enhance the user experience, we've implemented sticky content blocks that remain fixed on the screen while visitors horizontally scroll through Aston Martin's captivating articles. This seamless feature enables users to effortlessly delve into the content, accessing vital information and engaging visuals without losing sight of the main narrative.", 'This innovative design approach streamlines navigation, empowering the audience to enjoy an uninterrupted and immersive journey through our articles.']
          }]}
        />
        
      </div>
      <div data-background="ltAstonMartin" className="h-half-screen"></div>
    </Layout>
  )
}

export default AstonMartinPage
